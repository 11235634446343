import { AnalyticsProvider } from '@cheqroom/analytics';
import { useEventListener } from '@cheqroom/hooks';
import { ModalProvider, Portal, ThemeProvider } from '@cheqroom/ui';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { FC, StrictMode, Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { AuthenticationProvider } from './context/Authentication.context';
import { FeatureFlagsProvider } from './context/FeatureFlag.context';
import Client from './services/analytics';
import i18n from './services/i18n';
import { queryClient } from './services/react-query';

interface ComponentEvent {
	component: string;
	props: Record<string, unknown>;
	target: HTMLElement;
}

const container = document.getElementById('react-root') as HTMLElement;
const root = createRoot(container);

export const Root: FC = () => {
	const [components, setComponents] = useState<ComponentEvent[]>([]);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const lokaliseMode = params.get('lokalise_mode');

		if (process.env.ENVIRONMENT !== 'production' && lokaliseMode === 'debug') {
			void i18n.changeLanguage('cimode');
		}
	}, []);

	useEventListener('cheqroom-add-component', (event) => {
		setComponents((components) => [...components, (event as CustomEvent<ComponentEvent>).detail]);
	});

	useEventListener('cheqroom-update-component', (event) => {
		const updated = (event as CustomEvent<ComponentEvent>).detail;
		setComponents((components) => {
			return components.map((component) => {
				if (component.target.id === updated.target.id) return updated;
				return component;
			});
		});
	});

	useEventListener('cheqroom-remove-component', (event) => {
		const target = (event as CustomEvent<ComponentEvent>).detail.target;
		setComponents((components) => components.filter((component) => component.target.id !== target.id));
	});

	return (
		<ThemeProvider>
			<AuthenticationProvider>
				<FeatureFlagsProvider>
					<AnalyticsProvider analytics={Client}>
						<QueryClientProvider client={queryClient}>
							<ModalProvider>
								{components.map((component) => (
									<Portal key={component.target.id} target={component.target}>
										<Suspense fallback={null}>
											<ErrorBoundary>
												<component.component {...component.props} />
											</ErrorBoundary>
										</Suspense>
									</Portal>
								))}
							</ModalProvider>
						</QueryClientProvider>
					</AnalyticsProvider>
				</FeatureFlagsProvider>
			</AuthenticationProvider>
		</ThemeProvider>
	);
};

export const mount = (): void => {
	root.render(<Root />);
};

export const unmount = (): void => {
	root.unmount();
};
