import { ReactNode } from 'react';
import { css } from 'styled-components';
import tw, { styled } from 'twin.macro';

import { StyledOverlay } from '../Overlay/Overlay.styles';
import { StyledBody } from './components/Body/Body.styles';
import { StyledFooter } from './components/Footer/Footer.styles';
import { StyledHeader } from './components/Header/Header.styles';
import { Props } from './Modal';

export const StyledModal = styled.div<Pick<Props, 'size'>>`
	${tw`relative flex flex-col w-full overflow-auto bg-white rounded-md shadow-xl mx-4 sm:mx-auto my-8 divide-gray-200 divide-y`}

	max-height: calc(100vh - 60px);

	${({ size }) => {
		switch (size) {
			case 'small':
				return css`
					width: 300px;
				`;
			default:
			case 'medium':
				return css`
					width: 640px;
				`;
			case 'large':
				return css`
					width: 900px;
				`;
			case 'full-page':
				return css`
					${tw`
						w-full
						h-full
						max-h-full
						rounded-none
						m-0
						bg-gray-50
						divide-y-0
					`}

					${StyledHeader} {
						${tw`w-full max-w-[1028px] mx-auto my-0`}
					}

					& > span {
						${tw`flex flex-col flex-grow`}

						${StyledBody} {
							${tw`w-full max-w-[1028px] mx-auto my-0 rounded-none flex-grow px-6 pb-6 border-none`}
						}
					}

					${StyledFooter} {
						${tw`bg-white w-full flex items-center block border! border-t! border-gray-200!`};

						& > * {
							${tw`w-full max-w-[1028px] mx-auto my-0 flex justify-end px-6`}
						}
					}
				`;
		}
	}}
`;

export const StyledModalTransition = styled.div<{ children?: ReactNode }>`
	${tw`fixed inset-0 bottom-0 flex items-start justify-center`}

	//TODO move this to z-index helper
	z-index: 1080;

	// Reuse overlay
	${StyledOverlay} {
		${tw`z-0 pointer-events-auto`}
		background: rgba(51, 65, 85, 0.5);
	}

	&.modal-enter {
		${StyledOverlay} {
			${tw`opacity-0`}
		}

		${StyledModal} {
			${tw`transform translate-y-1/4 opacity-0`}
		}
	}

	&.modal-enter-active {
		${StyledOverlay} {
			${tw`transition duration-75 ease-in opacity-100`}
		}

		${StyledModal} {
			${tw`transition translate-y-0 ease-in opacity-100 duration-150`}
		}
	}

	&.modal-exit {
		${StyledOverlay} {
			${tw`opacity-100`}
		}

		${StyledModal} {
			${tw`transform translate-y-0`}
		}
	}

	&.modal-exit-active {
		${StyledOverlay} {
			${tw`transition duration-75 ease-in opacity-0`}
		}

		${StyledModal} {
			${tw`transition ease-in duration-150 opacity-0 translate-y-1/4`}
		}
	}
`;
